import uploadApi from '@apis/upload.api';
import Document from '@models/Document.model';
import { getBase64 } from '@utils/getBase64.util';
import { Image, Progress, message } from 'antd';
import { UploadProps } from 'antd/es/upload';
import Upload, { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { useEffect, useState } from 'react';
import UploadImageIcon from '@assets/images/icons/upload-image.svg';
import { b64toBlob } from '@utils/b64toBlob.util';
import { errorCatchHandler } from '@utils/errorCatch.util';
import { XCircle } from '@phosphor-icons/react';

interface UploadImageFormProps {
  form: any;
  name: string;
  preValue: string;
  dispatch: (value: string) => void;
  hasNote?: boolean;
  toggleModified?: (value: boolean) => void;
  preventPreview?: boolean;
  removeMedia: () => void;
}

export const UploadImageMedicalProductForm = ({
  form,
  name,
  preValue,
  hasNote = true,
  toggleModified = (value: boolean) => {
    return;
  },
  preventPreview = false,
  removeMedia,
}: UploadImageFormProps) => {
  const [imageAction, setImageAction] = useState<Document>();
  const [image, setImage] = useState(preValue);
  const [isLoadingImage, setIsloadingImage] = useState(false);
  const [progressImage, setProgressImage] = useState(0);
  const [abortUpload, setAbortUpload] = useState(false);
  const [isHasNode, setIsHasNote] = useState(hasNote);
  const uploadImage = async () => {
    const convertToB64 = b64toBlob(image);
    await uploadApi.uploadImage(convertToB64, imageAction?.uploadUrl ?? '', convertToB64.type);
  };
  useEffect(() => {
    uploadImage();
  }, [image]);
  useEffect(() => {
    if (image) {
      form.setFieldValue(name, image);
    }
  }, []);

  const handleRequestModuleImage = async () => {
    try {
      const response = await uploadApi.requestUpload('medicalProduct');
      if (response.data.success) {
        setImageAction(response.data.data);
      }
      return response.data.data;
    } catch (error) {
      errorCatchHandler(error);
    }
  };

  const beforeUploadImage = async (file: RcFile) => {
    const toggleFunction = toggleModified as (value: boolean) => void;
    toggleFunction(true);
    const imageUrl = (await handleRequestModuleImage()) as Document;
    form.setFieldValue(name, imageUrl.destination);
    if (
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/svg+xml'
    ) {
      const isImageCheck =
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/svg+xml';

      const imageSize = file.size / 1024 / 1024 < 5;

      if (!isImageCheck) {
        message.error('You can only upload JPG/JPEG/PNG/SVG file');
      }

      if (!imageSize) {
        message.error('You can only upload less than 5MB');
      }
      if (abortUpload) {
        setAbortUpload(false);
      }
      setImageAction(imageUrl);

      toggleFunction(isImageCheck && imageSize);
      return isImageCheck && imageSize;
    }
  };

  const handleUploadImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (abortUpload === true) {
      info.file.status = 'error';
      setIsloadingImage(false);
      setProgressImage(0);
      setAbortUpload(true);

      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setImage(url);
        setIsHasNote(false);
      });
      setIsloadingImage(false);
      setProgressImage(0);
    } else if (info.file.status === 'error') {
      setIsloadingImage(false);
      setProgressImage(0);
    } else if (info.file.status === undefined) {
      setIsloadingImage(false);
      setProgressImage(0);
      setAbortUpload(false);
    } else {
      const percent = info.file.percent;

      if (percent) {
        setProgressImage(Math.round(percent));
      }

      setIsloadingImage(true);
      setAbortUpload(false);
      setIsHasNote(false);
    }
  };

  const uploadImageButton = (
    <div
      className={`w-full h-[145px] aspect-square max-w-[100%] max-h-[300px] p-4 
        flex flex-col gap-2 items-center justify-center
      bg-white border-primary border-2 border-solid rounded-lg`}
    >
      <img alt="img" src={UploadImageIcon} className={`flex justify-center items-center`} />
      <div className={`text-primary font-normal text-[14px]`}>
        Drop or browse to <strong>upload image files</strong>
      </div>
    </div>
  );

  return (
    <div className={`w-full h-full flex gap-4 ${isHasNode ? 'justify-start' : 'justify-center'}`}>
      <Upload
        onChange={handleUploadImage}
        maxCount={1}
        accept=".svg, .jpg, .jpeg, .png"
        beforeUpload={beforeUploadImage}
        method="PUT"
        action={imageAction?.uploadUrl}
        showUploadList={false}
        className={`cursor-pointer flex items-center w-full`}
      >
        {isLoadingImage || progressImage > 0 ? (
          <div className={`w-full `}>
            <Progress
              className={`w-full h-full`}
              percent={progressImage}
              type="circle"
              strokeColor={{
                '0%': '#4D4479',
                '20%': '#2D5E98',
                '80%': '#00A7AC',
                '100%': '#36b569',
              }}
            />
          </div>
        ) : (
          <div className={`w-full h-fit flex`}>
            {image && !preventPreview ? (
              <div
                className={`w-full h-full flex flex-col  gap-4`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className={`h-[80%]`}>
                  <div className="w-fit relative">
                    <Image
                      height={'auto'}
                      src={image}
                      alt="uploadedImage"
                      className={`rounded-lg object-cover `}
                      style={{
                        objectFit: 'cover',
                        maxWidth: '145px',
                        maxHeight: '145px',
                      }}
                      preview
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <div
                      className={` absolute top-[-12px] right-[-16px] `}
                      onClick={(e) => {
                        e.stopPropagation();
                        setImage('');
                        removeMedia();
                      }}
                    >
                      <XCircle weight="fill" size={28} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              uploadImageButton
            )}
          </div>
        )}
      </Upload>
    </div>
  );
};
