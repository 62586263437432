interface ApproveRejectButtonProps {
  rejectClick?: () => void;
  approveClick?: () => void;
}

export const ApproveRejectButton = ({ rejectClick, approveClick }: ApproveRejectButtonProps) => {
  return (
    <div className="flex flex-row justify-end">
      <button
        onClick={rejectClick}
        className="w-[143px] h-[53px] p-[10px] border-solid border-[#DADADA] rounded-lg border-[1px] text-[#606060] text-[16px] flex justify-center mr-[10px] cursor-pointer items-center"
      >
        Reject
      </button>
      <button
        onClick={approveClick}
        className="w-[143px] h-[53px] p-[10px] bg-[#079455] text-white rounded-lg text-[16px] flex justify-center items-center cursor-pointer border-0"
      >
        Approve
      </button>
    </div>
  );
};
