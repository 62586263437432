import { MediumFont20Gray } from '@components/text-components/TextComponents';
import { HorizontalLine } from '@components/HorizontalLine';
import { OrderSummaryDetail } from './OrderSummaryDetail';

export interface PrescribedPack {
  question: string;
  answers: string[];
}

export interface OrderSummary {
  nonPrescribedPacks: OrderSummaryType[];
  prescribedPacks: OrderSummaryType[];
}

export interface OrderSummaryType {
  medicineId: string;
  pack: string;
  packOrder: number;
  medicineName: string;
}

interface Props {
  OrderSummaryData: OrderSummaryType[];
}

// const orderSummaryData: OrderSummary = {
//   nonPrescribedPacks: [
//     'Vitamin C Boost',
//     'Daily Energy Essentials',
//     'Omega-3 Fish Oil',
//     'Probiotic Support',
//   ],
//   prescribedPacks: ['Heart Health Pack', 'Immune Support Pack', 'Joint Care Bundle'],
// };

export const OrderDetail = ({ OrderSummaryData }: Props) => {
  const prescribedPacksFilter = OrderSummaryData.filter((order) => order.packOrder === 5);
  const nonPrescribedPacksFilter = OrderSummaryData.filter((order) => order.packOrder !== 5);
  const filterData = {
    prescribedPacks: prescribedPacksFilter,
    nonPrescribedPacks: nonPrescribedPacksFilter,
  };
  return (
    <div>
      <div className="mt-[32px] mb-[32px]">
        <HorizontalLine />
      </div>
      <MediumFont20Gray text="Order Summary" />
      <OrderSummaryDetail orderSummary={filterData} />
    </div>
  );
};
