import CaretLeft from '@assets/images/icons/caret-left-icon.svg';
import DiagnosticContainer from './privateChatDiagnostic/DiagnosticContainer';
import diagnosticApi from '@apis/diagnostic.api';
import { useCallback, useEffect, useState } from 'react';
import { DiagnosticModel } from '@models/Diagnostic.model';
import { Skeleton } from 'antd';
import NoMatch from '@components/NoMatch';
import { useNavigate } from 'react-router-dom';

interface PrivateChatDiagnosticProp {
  userId: string;
  onClick: () => void;
}

const PrivateChatDiagnostic = ({ userId, onClick }: PrivateChatDiagnosticProp) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [diagnosticDetail, setDiagnosticDetail] = useState<DiagnosticModel>();

  const navigate = useNavigate();

  const handleGetDiagnosticId = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await diagnosticApi.getDiagnosticByUserId(userId);
      if (response.data.success) {
        if (response.data.data.diagnosticMultiUsers.length > 0) {
          handleGetDiagnosticDetail(response.data.data.diagnosticMultiUsers[0].id);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [userId]);

  const handleGetDiagnosticDetail = async (diagnosticId: string) => {
    try {
      const response = await diagnosticApi.getDiagnosticDetail(diagnosticId);
      if (response.data.success) {
        setDiagnosticDetail(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleGetDiagnosticId();
  }, [userId, handleGetDiagnosticId]);
  return (
    <>
      {isLoading ? (
        <Skeleton className="p-[20px]" />
      ) : (
        <>
          <div className="w-full h-full p-[20px] ">
            <div className="w-full flex flex-row justify-between">
              <div className="flex flex-row cursor-pointer" onClick={onClick}>
                <img alt="" src={CaretLeft} className="w-[24px] h-[24px] mt-[8px] mr-[10px]" />
                <p className="text-[20px] font-bold text-[#1E1E1E] mt-[4px]">
                  {' '}
                  {diagnosticDetail ? 'Diagnosis #1' : ''}{' '}
                </p>
              </div>

              <p
                className="text-[#919191] text-[12px] underline decoration-solid mt-[8px] cursor-pointer"
                onClick={() => {
                  navigate(`/user-profile/detail/${userId}/diagnostic-detail`);
                }}
              >
                {diagnosticDetail ? 'See full' : ''}
              </p>
            </div>
            {diagnosticDetail ? (
              <div className="flex flex-col">
                <DiagnosticContainer
                  title="General statement"
                  detail={diagnosticDetail.diagnosticSummary.generalStatements?.join(' ') || ''}
                />
                <div className="mt-[24px]  h-full overflow-y-auto  ">
                  <p className="text-primary text-[20px] font-bold  ">
                    Answer to all questions in the diagnostics
                  </p>
                  {diagnosticDetail.diagnosticQuestionUserAnswers?.map((data, index) => (
                    <div className="rounded-2xl p-[8px] border-solid border-2 h-fit  border-[#DADADA] flex flex-row mb-[12px] ">
                      <p className="text-[#4C4C4C] font-bold text-[14px] m-0">{index + 1}.</p>
                      <div>
                        <p className="text-[#4C4C4C] font-bold text-[14px] m-0">{data.question}</p>
                        <p className="text-[#4C4C4C] text-[14px] m-0 line-clamp-1 ">
                          {data.answers}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <NoMatch
                title=""
                content="This user has not complete diagnostic"
                description="Back to user profile page"
                pathName="/user-profile"
                className={` w-full flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PrivateChatDiagnostic;
