import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MedicalOrderState } from './medicalOrder.state';

const initialState: MedicalOrderState = {
  currentPage: 1,
};

const MedicalProductReducer = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<MedicalOrderState>) {
      state.currentPage = action.payload.currentPage;
    },
  },
});

export const { setCurrentPage } = MedicalProductReducer.actions;
export default MedicalProductReducer.reducer;
