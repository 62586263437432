import { Table } from 'antd';
import './TableComponent.style.scss';

interface props {
  columns: any;
  dataSource: any;
  handleChange: (pagination: any) => void;
  pagination: any;
  currentPage: number;
  totalData: number;
  isLoading: boolean;
}

export const TableComponent = ({
  columns,
  dataSource,
  handleChange,
  pagination,
  currentPage,
  isLoading,
  totalData,
}: props) => {
  return (
    <Table
      className="table-user-profile"
      loading={isLoading}
      rowKey="key"
      columns={columns}
      dataSource={dataSource}
      onChange={(pagination) => handleChange(pagination)}
      pagination={{
        total: totalData,
        pageSize: pagination.limit,
        defaultCurrent: currentPage,
        position: ['bottomCenter'],
      }}
    />
  );
};
