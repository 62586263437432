interface TextComponentsInterface {
  text: string;
}

export const MediumFont20Gray = ({ text }: TextComponentsInterface) => {
  return <p className="text-[#4C4C4C] text-[20px] font-[600] m-0 p-0">{text}</p>;
};

export const NormalFont16Black = ({ text }: TextComponentsInterface) => {
  return <p className="text-[#4C4C4C] text-[16px] font-[400] m-0 p-0">{text}</p>;
};

export const NormalFont18Black = ({ text }: TextComponentsInterface) => {
  return <p className="text-[#4C4C4C] text-[18px] font-[500] m-0 p-0">{text}</p>;
};

export const BoldFont16BlackZambezi = ({ text }: TextComponentsInterface) => {
  return <p className="text-[#606060] text-[16px] font-[600] m-0 p-0">{text}</p>;
};
