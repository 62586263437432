import {
  BoldFont16BlackZambezi,
  MediumFont20Gray,
  NormalFont16Black,
} from '@components/text-components/TextComponents';

import { Image, message, Tag } from 'antd';
import { getTagColor } from '@utils/converTagColor.util';
import { PrescribedPack } from './OrderDetail';
import { ApproveRejectButton } from './ApproveRejectButton';
import { medicalApi } from '@apis/medical.api';
import './PrescribedPackDetail.style.scss';

interface PrescribedPackDetailProps {
  data: PrescribedPack[];
  governmentIssueImg: string[];
  status: string;
  orderId: string;
  reloadData: () => void;
}

export const PrescribedPackDetail = ({
  data,
  governmentIssueImg,
  status,
  orderId,
  reloadData,
}: PrescribedPackDetailProps) => {
  const handleApprove = async () => {
    try {
      const response = await medicalApi.updateMedicalOrderStatus(orderId, true);
      if (response.data.success) {
        reloadData();
      }
    } catch (error) {
      message.error(error as any);
    }
  };

  const handleReject = async () => {
    try {
      const response = await medicalApi.updateMedicalOrderStatus(orderId, false);
      if (response.data.success) {
        reloadData();
      }
    } catch (error) {
      message.error(error as any);
    }
  };
  return (
    <div>
      <div className="flex flex-row items-center">
        <MediumFont20Gray text="Prescribed pack" />
        {status.toLowerCase() !== 'nonprescription' && (
          <Tag
            className="rounded-full w-[88px] text-center flex items-center h-[21px] text-[14px] font-medium"
            style={{
              borderRadius: '12px',
              marginLeft: '16px',
              color: getTagColor(status)?.textColor,
            }}
            color={getTagColor(status)?.backgroundColor}
            key={''}
          >
            • {status}
          </Tag>
        )}
      </div>
      <div className="pl-[12px] pr-[12px]">
        {data.map((e) => (
          <div className="mt-[16px] mb-[16px] flex flex-row gap-1">
            <NormalFont16Black text={`• ${e.question}`} />
            {e.answers!.length > 0 && (
              <>
                {e.answers!.map((e) => (
                  <BoldFont16BlackZambezi text={` ${e}`} />
                ))}
              </>
            )}
          </div>
        ))}
      </div>
      {governmentIssueImg && governmentIssueImg.length > 0 && (
        <div className="pl-[12px] pr-[12px]">
          <div className="mt-[16px] mb-[16px] flex flex-col gap-1">
            <NormalFont16Black text={`• Government issued ID`} />
            <div className="flex flex-row gap-1">
              {governmentIssueImg.map((e) => (
                <Image
                  src={e}
                  alt=""
                  width={'150px'}
                  height={'150px'}
                  style={{
                    marginRight: '12px',
                    borderRadius: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {status === 'Pending' && (
        <div className="mt-[32px]">
          <ApproveRejectButton approveClick={handleApprove} rejectClick={handleReject} />
        </div>
      )}
    </div>
  );
};
