import { LeftOutlined } from '@ant-design/icons';
import { HorizontalLine } from '@components/HorizontalLine';
import NoMatch from '@components/NoMatch';
import routes from '@routes';
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDetail } from './list-order-detail/OrderDetail';
import UserOrderDetail from './list-order-detail/UserOrderDetail';
import { medicalApi } from '@apis/medical.api';
import { PrescribedPackDetail } from './list-order-detail/PrescribedPackDetail';

export type MedicalOrderUserDetail = {
  recipientId: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  identifications: string[];
  birthdate: string;
};

export type OrderDetailType = {
  id: string;
  status: string;
  requestDate: string; // ISO date string
  reviewDate: string; // ISO date string
  user: MedicalOrderUserDetail;
  prescribedPack: {
    question: string;
    answers: string[];
  }[];
  orderSummary: {
    medicineId: string;
    pack: string;
    packOrder: number;
    medicineName: string;
  }[];
};
export const OrderDetailPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [orderDetailData, setOrderDetailData] = useState<OrderDetailType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetOrderDetail = async () => {
    try {
      setIsLoading(true);
      const response = await medicalApi.getMedicalOrderDetail(orderId ?? '');
      if (response.data.success) {
        setOrderDetailData(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    handleGetOrderDetail();
  }, []);
  return (
    <div className="h-full overflow-auto">
      {isLoading ? (
        <Skeleton className="p-[40px]" />
      ) : (
        <div>
          {orderDetailData ? (
            <div className="p-[40px] h-full  ">
              <div className={`flex flex-row justify-left items-center gap-8`}>
                <LeftOutlined
                  className={`text-primary text-[22px]`}
                  onClick={() => {
                    // navigate(`/user-profile/detail/${userName}`);

                    navigate(routes.ListOfOrder.path);
                  }}
                />
                <div className={`text-3xl text-primary font-bold flex flex-row`}>
                  Medical orders<div className="font-normal">/{orderId}</div>
                </div>
              </div>
              <UserOrderDetail
                userDetail={orderDetailData.user}
                orderId={orderId ?? ''}
                reloadData={handleGetOrderDetail}
                isEditDisable={orderDetailData.prescribedPack.length === 0}
              />
              {orderDetailData.prescribedPack.length > 0 && (
                <>
                  <div className="mt-[32px] mb-[32px]">
                    <HorizontalLine />
                  </div>
                  <div>
                    <PrescribedPackDetail
                      orderId={orderId ?? ''}
                      reloadData={handleGetOrderDetail}
                      status={orderDetailData.status}
                      data={orderDetailData.prescribedPack}
                      governmentIssueImg={orderDetailData?.user?.identifications ?? []}
                    />
                  </div>
                </>
              )}
              <OrderDetail OrderSummaryData={orderDetailData?.orderSummary} />
            </div>
          ) : (
            <NoMatch
              title=""
              content="This user has not complete diagnostic"
              description="Back to user profile page"
              pathName="/user-profile"
              className={`absolute w-full flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
            />
          )}
        </div>
      )}
    </div>
  );
};
