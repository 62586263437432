import UserIcon from '@assets/images/icons/user-icon.svg';
import PhoneIcon from '@assets/images/icons/phone-icon.svg';
import EmailIcon from '@assets/images/icons/email-icon.svg';
import AddressIcon from '@assets/images/icons/address-icon.svg';
import EditIcon from '@assets/images/icons/edit-icon.svg';
import { useState } from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import '../ListOrder.styles.scss';
import { medicalApi } from '@apis/medical.api';
import { MedicalOrderUserDetail } from '../OrderDetail.page';
import { ConvertCountryCodeToName } from '@utils/convertCountryCodeToName.util';
interface UserDetailProps {
  userDetail: MedicalOrderUserDetail | undefined;
  orderId: string;
  reloadData: () => void;
  isEditDisable: boolean;
}

const UserOrderDetail = ({ userDetail, orderId, reloadData, isEditDisable }: UserDetailProps) => {
  const [isClickEdit, setIsClickEdit] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const onClickModal = () => {
    setIsClickEdit(!isClickEdit);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const onSubmit = async () => {
    try {
      setIsEditing(true);
      const payload = {
        firstName: form.getFieldValue('firstName'),
        lastName: form.getFieldValue('lastName'),
        phone: form.getFieldValue('phone'),
        email: form.getFieldValue('email'),
        city: form.getFieldValue('city'),
        state: form.getFieldValue('state'),
        postalCode: form.getFieldValue('postalCode'),
        address: form.getFieldValue('address'),
        country: form.getFieldValue('country'),
      };
      const response = await medicalApi.updateMedicalOrderUser(orderId, payload);
      if (response.data.success) {
        reloadData();
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      message.error('something wrong');
      setIsEditing(false);
    }
  };
  const formatDate = (dateString: string | undefined) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(date);
      const ageDiffMs = Date.now() - date.getTime();
      const ageDate = new Date(ageDiffMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      return (
        <>
          {formattedDate} (<strong>{age} yrs</strong>)
        </>
      );
    }
  };
  const onValuesChange = async () => {
    try {
      await form.validateFields();
      setIsDisabled(false);
    } catch (errors: any) {
      if (errors.errorFields.length > 0) {
        setIsDisabled(true);
      } else setIsDisabled(false);
    }
  };

  return (
    <div className="detail-container w-full shadow-[#00000021] rounded-xl p-[20px] mt-[20px]">
      <Form
        layout="vertical"
        form={form}
        initialValues={userDetail}
        onValuesChange={onValuesChange}
      >
        <div className=" flex flex-row justify-between border-t-0 border-l-0 border-r-0 border-b-[1px] border-solid border-[#C2C2C2] ">
          <div className="flex flex-row align-top">
            <p className="text-[24px] text-primary font-bold">PT101 - {userDetail?.username}</p>
            <p className="ml-[24px] text-[16px] text-[#606060] mt-[4px]">
              {formatDate(userDetail?.birthdate)}
            </p>
            <Button
              disabled={isEditDisable}
              className="border-0 w-fit h-fit ml-[24px] cursor-pointer !bg-transparent"
              onClick={onClickModal}
            >
              <img alt="" className="" src={EditIcon} />
            </Button>
          </div>
        </div>
        <div className="grid grid-rows-9 grid-cols-2 grid-flow-col gap-3 mt-[20px] w-[280px] items-center">
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={UserIcon} />
            First name:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <div className="w-[24px] h-[24px] mr-[10px]" />
            Last name:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={PhoneIcon} />
            Phone:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={EmailIcon} />
            Email:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={AddressIcon} />
            Address:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <div className="w-[24px] h-[24px] mr-[10px]" />
            City:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <div className="w-[24px] h-[24px] mr-[10px]" />
            State:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <div className="w-[24px] h-[24px] mr-[10px]" />
            Postal code:
          </p>
          <p className="text-[16px] text-[#000000] flex flex-row font-semibold m-0 p-0">
            <div className="w-[24px] h-[24px] mr-[10px]" />
            Country:
          </p>
          {isClickEdit ? (
            <>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal "
                  name="firstName"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="First name" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal "
                  name="lastName"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="Last name" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="phone"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="Phone" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="email"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="Email" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="address"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="Address" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="city"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="City" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="state"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="State" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="postalCode"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Input className={`user-input`} placeholder="Postal Code" maxLength={50} />
                </Form.Item>
              </div>
              <div className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">
                <Form.Item
                  className="edit-user-detail-modal"
                  name="country"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Select
                    className="select-input"
                    style={{ width: '400px', borderColor: '#DADADA' }}
                    onChange={() => {}}
                    options={[
                      { value: 'CA', label: 'Canada' },
                      { value: 'US', label: 'America' },
                    ]}
                  />
                </Form.Item>
              </div>
            </>
          ) : (
            <>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.firstName}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.lastName}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.phone}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.email}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.address}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.city}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.state}
              </p>
              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {userDetail?.postalCode}
              </p>

              <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] m-0 p-0">
                {ConvertCountryCodeToName(userDetail?.country ?? '')}
              </p>
            </>
          )}
        </div>
        {isClickEdit && (
          <div className="flex flex-row justify-end">
            <Button
              onClick={() => setIsClickEdit(false)}
              className="w-[84px] h-[45px] p-[10px] border-solid border-[#DADADA] rounded-lg border-[1px] text-primary text-[16px] flex justify-center mr-[10px] cursor-pointer"
            >
              Cancel
            </Button>
            {!isEditing ? (
              <Button
                disabled={isDisabled}
                className="w-[84px] h-[45px] p-[10px] bg-primary text-white rounded-lg text-[16px] flex justify-center cursor-pointer border-0"
                onClick={onSubmit}
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                loading={isEditing}
                disabled={isEditing}
                htmlType="submit"
                className="w-[84px] h-[45px] p-[10px] bg-[#8B90C6] text-white rounded-lg text-[16px] flex justify-center cursor-pointer border-0"
                type="primary"
              >
                Saving
              </Button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};
export default UserOrderDetail;
