import SearchButton from '@components/SearchButton';
import { Button, Image, message, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import { MedicalProductModal } from './add-product/MedicalProductModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './ListOfProduct.style.scss';
import { TableComponent } from '@components/TableComponent';
import { medicalApi } from '@apis/medical.api';
import { MedicalParams } from '@utils/medical.util';
import { naturopathicApi } from '@apis/naturopathic.api';
import { ConvertCountryCodeToName } from '@utils/convertCountryCodeToName.util';

export interface ProductType {
  id: string;
  pack: string;
  packOrder: number;
  naturopathic: string;
  naturopathicCode: string;
  region: string;
  name: string;
  pictureUrl: string;
  productNumber: string;
  price: number;
  direction: string;
}

interface ProductResponse {
  products: ProductType[];
  totalPages: number;
  currentPage: number;
  totalCount: number;
}

export interface Naturopathic {
  code: string;
  name: string;
}

export interface PackType {
  pack: string;
  packOrder: number;
  naturopathics: Naturopathic[];
}

const initPagination = {
  page: 1,
  pageSize: 10,
};

export const ListOfProductPage = () => {
  const [productData, setProductData] = useState<ProductResponse>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(initPagination);
  const [searchValue, setSearchValue] = useState<string>('');
  const [packList, setPackList] = useState<PackType[]>([]);
  const [editData, setEditData] = useState<ProductType>();
  const [model, setModel] = useState<string>();
  const { confirm } = Modal;

  const getMedicalProductList = async () => {
    try {
      setIsLoading(true);
      const params: MedicalParams = {
        pageNumber: pagination.page,
        pageSize: pagination.pageSize,
        search: searchValue,
      };
      const response = await medicalApi.getListMedicalProduct(params);
      if (response.data.success) {
        setProductData(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      message.error(error as any);
    }
  };

  const handleGetNaturopathicList = async () => {
    try {
      const response = await naturopathicApi.getListNaturopathics();
      if (response.data.success) {
        setPackList(response.data.data.packs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetNaturopathicList();
  }, []);

  useEffect(() => {
    getMedicalProductList();
  }, [pagination, searchValue]);

  const addNewMedicalProduct = () => {
    setModel('add');
    setIsModalOpen(true);
  };

  const editMedicalProduct = (productData: ProductType) => {
    setModel('edit');
    setEditData(productData);
    setIsModalOpen(true);
  };

  const onCancelCreate = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (id: string) => {
    const icon = <ExclamationCircleOutlined style={{ color: '#D01616' }} />;
    const title = <h3 className={`font-bold text-primary text-left`}>{`Delete this product?`}</h3>;
    const content = (
      <h4 className={`text-black font-normal text-[16px] text-center`}>
        Are you sure you want to delete this product?
      </h4>
    );
    const onOk = async () => {
      try {
        const response = await medicalApi.deleteMedicalProduct(id);
        if (response.data.success) {
          getMedicalProductList();
          message.success('Delete medical product successfull');
        }
      } catch (error) {}
    };
    const onCancel = () => {
      return;
    };
    const bodyStyle = {
      background: 'white',
      borderRadius: 10,
      width: 'fit-content',
      padding: 'none',
    };
    const okButtonProps = {
      style: {
        borderRadius: 4,
        width: 90,
        height: 40,
        backgroundColor: '#4D4479',
        transition: 'filter 0.3s',
        fontFamily: "'WorkSans', sans-serif",
        fontSize: '14px',
      },
      onMouseEnter: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.currentTarget.style.filter = 'brightness(150%)';
      },
      onMouseLeave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.currentTarget.style.filter = 'none';
      },
    };
    const cancelButtonProps = {
      style: {
        fontFamily: "'WorkSans', sans-serif",
        borderRadius: 4,
        width: 90,
        height: 40,
        backgroundColor: '#F5F5F5',
        color: '#4D4479',
        fontSize: '14px',
      },
    };
    confirm({
      className: 'custom-modal !rounded-[10px]',
      icon: icon,
      maskClosable: true,
      centered: true,
      width: 550,
      okText: 'Delete',
      cancelText: 'Cancel',
      autoFocusButton: null,
      title: title,
      content: content,
      onOk: onOk,
      onCancel: onCancel,
      bodyStyle: bodyStyle,
      okButtonProps: okButtonProps,
      cancelButtonProps: cancelButtonProps,
    });
  };
  const columns: ColumnsType<ProductType> = [
    {
      title: 'Pack',
      dataIndex: 'pack',
      className: 'column-first-item',
      key: 'pack',
    },
    {
      title: 'Naturopathic list',
      dataIndex: 'naturopathic',
      key: 'naturopathic',
    },
    {
      title: 'Region',
      key: 'region',
      dataIndex: 'region',
      render: (country) => {
        return <>{ConvertCountryCodeToName(country)}</>;
      },
    },
    {
      title: 'Product name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Product image',
      dataIndex: 'pictureUrl',
      key: 'pictureUrl',
      render: (pictureUrl) => {
        return (
          <Image
            src={pictureUrl}
            width={80}
            height={80}
            className="object-cover"
            alt="product-image"
          />
        );
      },
    },
    {
      title: 'Product number',
      key: 'productNumber',
      dataIndex: 'productNumber',
    },
    {
      title: 'Product price',
      key: 'price',
      dataIndex: 'price',
      render: (price) => {
        return <div>{`$${price}`}</div>;
      },
    },
    {
      title: 'Product direction',
      key: 'direction',
      dataIndex: 'direction',
    },
    {
      title: 'Edit',
      render: (productData: ProductType) => (
        <div
          className="underline text-[#2F6BFF] cursor-pointer"
          onClick={() => editMedicalProduct(productData)}
        >
          Edit
        </div>
      ),
    },
    {
      title: 'Delete',
      key: 'id',
      dataIndex: 'id',
      className: 'column-last-item',
      render: (id) => (
        <div className="underline text-[#FE8783] cursor-pointer" onClick={() => handleDelete(id)}>
          Delete
        </div>
      ),
    },
  ];
  const handleChangePagination = (pagination: any) => {
    setPagination({
      page: pagination.current || initPagination.page,
      pageSize: pagination.pageSize || initPagination.pageSize,
    });
  };

  const handleSearch = async (value: string) => {
    if (pagination.page !== 1) {
      setPagination(initPagination);
    }
    setTimeout(() => {
      setSearchValue(value);
    }, 2000);
  };
  return (
    <>
      <div className="p-[40px] h-full overflow-auto ">
        <div className="flex flex-row justify-between w-full h-fit">
          <p className="text-3xl text-primary font-bold w-[300px] mt-[10px] ">Manage product</p>
          <div className="flex flex-row h-fit ">
            <SearchButton onClick={handleSearch} />
            <Button
              type="primary"
              className="h-fit flex justify-center items-center mt-[32px] px-6 py-3 text-[16px] font-[500] rounded-lg gap-1"
              onClick={addNewMedicalProduct}
            >
              <Plus size={20} /> Add new
            </Button>
          </div>
        </div>
        <div className="mt-[10px]">
          <TableComponent
            totalData={productData?.totalCount ?? 0}
            isLoading={isLoading}
            columns={columns}
            dataSource={productData?.products}
            pagination={pagination}
            handleChange={handleChangePagination}
            currentPage={pagination.page}
          />
        </div>
      </div>
      {isModalOpen && (
        <MedicalProductModal
          onModalOk={getMedicalProductList}
          onClose={onCancelCreate}
          onOpen={isModalOpen}
          model={model ?? ''}
          editData={model === 'edit' ? editData : undefined}
          packList={packList}
        />
      )}
    </>
  );
};
