import loggedClient from '../https/loggedClient.http';
import { MedicalParams } from '@utils/medical.util';

const baseMedical = '/admin/medicals';

export const medicalApi = {
  getListMedicalOrder: (params: MedicalParams) =>
    loggedClient.get(`${baseMedical}/orders`, { params: params }),
  getMedicalOrderDetail: (medicalOrderId: string) =>
    loggedClient.get(`${baseMedical}/orders/${medicalOrderId}`),
  updateMedicalOrderStatus: (medicalId: string, isApproved: boolean) =>
    loggedClient.post(`${baseMedical}/orders/${medicalId}/confirmation`, {
      isApproved: isApproved,
    }),
  updateMedicalOrderUser: (medicalOrderId: string, data: any) =>
    loggedClient.put(`${baseMedical}/orders/${medicalOrderId}`, data),
  getListMedicalProduct: (params: MedicalParams) =>
    loggedClient.get(`${baseMedical}/products`, { params: params }),
  createMedicalProduct: (payload: any) => loggedClient.post(`${baseMedical}/products`, payload),
  editMedicalProduct: (productId: string, payload: any) =>
    loggedClient.put(`${baseMedical}/products/${productId}`, payload, { params: productId }),
  deleteMedicalProduct: (productId: string) =>
    loggedClient.delete(`${baseMedical}/products/${productId}`),
};
