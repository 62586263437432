import accountApi from '@apis/account.api';
import Gift from '@assets/images/icons/gift.svg';
import Home from '@assets/images/icons/home.svg';
import Music from '@assets/images/icons/music.svg';
import LogOutButton from '@components/LogOutButton';
import ChatIcon from '@assets/images/icons/chat-circle.svg';
import ListOrderIcon from '@assets/images/icons/list-order-icon.svg';
import UserProfile from '@assets/images/icons/user-profile-icon.svg';
import AnaProgram from '@assets/images/icons/ana-program-icon.svg';
import RatingIcon from '@assets/images/icons/rating-icon.svg';
import PackageIcon from '@assets/images/icons/package-icon.svg';
import routes from '@routes';
import { MenuItem, findMenuItemIndex } from '@utils/findMenuItem.util';
import { INDICATOR_HEIGHT, getIndicator } from '@utils/getIndicator.util';
import { VERSION_NUMBER } from '@utils/version.util';
import { Layout, Menu } from 'antd';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/images/icons/logo-ana-mini.svg';
import './Sidebar.styles.scss';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '@pages/private-chat/HandleAccessFirebase';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
import { store } from '@state/store';
import { addUnReadMessage } from '@state/chat/chatReducer';
import {
  handleSetIsExpandFull,
  handleSetIsQuicklyScreen,
} from '@state/rating/handleSetStateRating/handleSetStateRating';
import { handleSetCurrentPageUserProfile } from '@state/user-profile/handleSetStateUserProfile/handleSetStateUserProfile';
import {
  handleSetFirstLoading,
  handleSetLimit,
  handleSetLimitList,
  handleSetSubscriptionExpired,
} from '@state/chat/handleSetStateChat/handleSetStateChat';
import { resetReply } from '@state/chat/replyChatReducer';
import {
  handleSetAnaProgramIsExpandFull,
  handleSetAnaProgramIsQuicklyScreen,
} from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';
import { handleSetCurrentPage } from '@state/medical-product/handleSetStateOrder/handleSetStateOrder';

const { Sider } = Layout;

const menuItems: MenuItem[] = [
  {
    ...routes.Affirmation,
    MenuIcon: <img src={Home} className={`min-w-[20%] h-auto pr-1`} alt="affirmations" />,
    key: routes.Affirmation.path,
  },
  {
    ...routes.DailyVibe,
    MenuIcon: <img src={Music} className={`min-w-[20%] h-auto pr-1`} alt="daily vibes" />,
    key: routes.DailyVibe.path,
  },
  // {
  //   path: routes.Session.path,
  //   title: 'Journey',
  //   MenuIcon: <img src={ArchiveBook} className={`min-w-[20%] h-auto pr-1`} alt="session" />,
  //   key: 'journey-menu',
  //   children: [
  //     {
  //       ...routes.Session,
  //       MenuIcon: <img src={SessionIcon} className={`min-w-[20%] h-auto pr-1`} alt="session" />,
  //       key: routes.Session.path,
  //     },
  //     {
  //       ...routes.Navigator,
  //       MenuIcon: <img src={OpeningBook} className={`min-w-[20%] h-auto pr-1`} alt="session" />,
  //       key: routes.Navigator.path,
  //     },
  //   ],
  // },

  {
    ...routes.AccessCode,
    MenuIcon: <img src={Gift} className={`min-w-[20%] h-auto pr-1`} alt="access code" />,
    key: routes.AccessCode.path,
  },
  // {
  //   ...routes.FeedbackPage,
  //   MenuIcon: <img src={FeedbackIcon} className={`min-w-[20%] h-auto pr-1`} alt="feedback" />,
  //   key: routes.FeedbackPage.path,
  // },

  // {
  //   ...routes.Telehealth,
  //   MenuIcon: <img src={TeleHealthIcon} className={`min-w-[20%] h-auto pr-1`} alt="telehealth" />,
  //   key: routes.Telehealth.path,
  // },

  {
    ...routes.PrivateChat,
    MenuIcon: <img src={ChatIcon} className={`min-w-[20%] h-auto pr-1`} alt="telehealth" />,
    key: routes.PrivateChat.path,
  },
  {
    ...routes.UserRating,
    MenuIcon: <img src={RatingIcon} className={`min-w-[20%] h-auto pr-1`} alt="telehealth" />,
    key: routes.UserRating.path,
  },
  {
    path: routes.UserProfile.path,
    title: 'Ana Program',
    MenuIcon: <img src={AnaProgram} className={`min-w-[20%] h-auto pr-1`} alt="user profile" />,
    key: 'ana-program-menu',
    children: [
      {
        ...routes.UserProfile,
        MenuIcon: (
          <img src={UserProfile} className={`min-w-[20%] h-auto pr-1`} alt="user profile" />
        ),
        key: routes.UserProfile.path,
      },
      {
        ...routes.ListOfOrder,
        MenuIcon: (
          <img src={ListOrderIcon} className={`min-w-[20%] h-auto pr-1`} alt="list order" />
        ),
        key: routes.ListOfOrder.path,
      },
      {
        ...routes.ListOfProduct,
        MenuIcon: (
          <img src={PackageIcon} className={`min-w-[20%] h-auto pr-1`} alt="list product" />
        ),
        key: routes.ListOfProduct.path,
      },
    ],
  },
];

interface UserProps {
  name: string;
  email?: string;
}

const Sidebar = () => {
  const location = useLocation();

  const [currentIndex, setCurrentIndex] = useState(
    getIndicator(
      menuItems.indexOf(menuItems.filter((item) => location.pathname.includes(item.path || ''))[0])
    )
  );
  const [journeyMenuOpen, setJourneyMenuOpen] = useState<'journey-menu' | undefined>(undefined);
  const [user, setUser] = useState<UserProps>();

  const toggleJourneyMenu = () => {
    setJourneyMenuOpen((prev) => (prev === undefined ? 'journey-menu' : undefined));
  };

  const getAdminUser = useCallback(async (userId: string) => {
    try {
      const response = await accountApi.getUser(userId);

      if (response.data.success) {
        setUser(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getUserToken = useCallback(async () => {
    try {
      const result = (await Auth.currentSession()).getIdToken().getJwtToken();
      const getParseToken = JSON.parse(window.atob(result.split('.')[1]));

      await getAdminUser(getParseToken.user_id);
    } catch (error) {
      console.error(error);
    }
  }, [getAdminUser]);

  useEffect(() => {
    getUserToken();
  }, [getUserToken]);

  useEffect(() => {
    if (location) {
      const currentIndex = findMenuItemIndex(location.pathname, menuItems);
      setCurrentIndex(getIndicator(currentIndex, journeyMenuOpen !== undefined));
    }
  }, [location, currentIndex, journeyMenuOpen]);

  const countUnread = useSelector((state: RootState) => state.privateChat.unreadMessage);

  useEffect(() => {
    const q = query(
      collection(db, `ana-private-chat/private-chat/sessions`),
      orderBy('timestamp', 'desc')
    );
    const snapShot = onSnapshot(q, async (snapShot) => {
      let count = 0;
      snapShot.docs.map(async (doc) => {
        if (doc.data().adminUnreadCount) {
          count = count + doc.data().adminUnreadCount;
        }
      });
      const payload = {
        unreadMessage: count,
        firstLoading: false,
        currentChatItem: '',
        limitItem: 15,
        limitList: 8,
        subscriptionExpired: false,
      };
      store.dispatch(addUnReadMessage(payload));
    });
    return () => {
      snapShot();
    };
  }, []);

  return (
    <Sider
      width={'16%'}
      className={`bg-primary selection:bg-light selection:text-primary h-full overflow-auto overflow-x-hidden`}
      id="scrollbar-sidebar"
    >
      <div className={`flex flex-col h-full bg-primary`}>
        <div className={`flex justify-center p-12 `}>
          <img src={logo} alt="" className={`login-logo`} />
        </div>
        <div className={`flex flex-col justify-between h-full w-full `}>
          <div className={`flex flex-col justify-between h-full w-full`}>
            <Menu
              mode="inline"
              selectedKeys={['/' + location.pathname.split('/')[1]]}
              className={`flex flex-col gap-2 `}
              style={{
                backgroundColor: '#4D4479',
                borderRight: 0,
              }}
              openKeys={[journeyMenuOpen ?? '']}
            >
              <div
                className={`indicator absolute  left-0  w-[7px] 
                bg-white rounded-r-lg  duration-500 ease-out`}
                style={{ top: currentIndex + 'px', height: INDICATOR_HEIGHT + 'px' }}
              ></div>

              {menuItems.map(({ MenuIcon, title, path, key, children }, index) => {
                return children === undefined ? (
                  <Menu.Item
                    onClick={() => {
                      handleSetFirstLoading(true);
                      handleSetLimitList(8);
                      handleSetLimit(15);
                      handleSetIsExpandFull(false);
                      handleSetIsQuicklyScreen(false);
                      store.dispatch(resetReply());
                      handleSetCurrentPage(1);
                      handleSetSubscriptionExpired(false);
                      handleSetAnaProgramIsQuicklyScreen(false);
                      handleSetAnaProgramIsExpandFull(false);
                      handleSetCurrentPageUserProfile(1);
                      handleSetCurrentPage(1);
                    }}
                    key={key}
                    icon={MenuIcon}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '50%',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      fontSize: '16px',
                      overflow: 'visible',
                      borderRadius: '10px',
                    }}
                  >
                    <NavLink to={path ?? '/'}>
                      {index === 3 ? (
                        <div className=" flex flex-row  space-x-1 relative">
                          <span className={`text-white `}>{title}</span>
                          {(countUnread || countUnread !== 0) && (
                            <div className=" absolute z-50 right-0 top-2">
                              <div className=" border-solid border-[2px] rounded-full bg-[red] w-[24px] h-[24px] relative  text-center text-[white] text-[12px]  ">
                                <p className=" w-full top-[-10px]  absolute m-0 p-0">
                                  {countUnread ? countUnread : 0}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <span className={`text-white `}>{title}</span>
                      )}
                    </NavLink>
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    title={title}
                    icon={MenuIcon}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: '50%',
                      fontSize: '16px',
                      overflow: 'visible',
                      borderRadius: '10px',
                    }}
                    onTitleClick={toggleJourneyMenu}
                    key={'journey-menu'}
                  >
                    {children.map(({ MenuIcon, title, path, key }) => {
                      return (
                        <Menu.Item
                          key={key}
                          icon={MenuIcon}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: 'fit-content',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            fontSize: '16px',
                            overflow: 'visible',
                            borderRadius: '10px',
                            background: 'none',
                          }}
                        >
                          <NavLink to={path ?? '/'}>
                            <span className={`text-white `}>{title}</span>
                          </NavLink>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              })}
            </Menu>

            <div className={`flex flex-col justify-center`}>
              <p
                className={`flex-auto w-[180px] text-center m-auto text-white justify-center text-[20px] mb-4 font-bold truncate`}
              >
                {user?.name}
              </p>
              <LogOutButton />
              <p className={`ml-[20px] mb-4 text-white`}>Version: {VERSION_NUMBER}</p>
            </div>
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
