import { Outlet } from 'react-router-dom';

const ListOrderContainer = () => {
  return (
    <div
      className={`w-full h-full flex`}
      style={{
        willChange: 'transform, opacity',
        WebkitUserSelect: 'none',
      }}
    >
      <div className={`w-full h-full overflow-hidden duration-200 ease-out absolute`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ListOrderContainer;
