import SearchButton from '@components/SearchButton';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getTagColor } from '@utils/converTagColor.util';
import { medicalApi } from '@apis/medical.api';
import { TableComponent } from '@components/TableComponent';
import { MedicalParams } from '@utils/medical.util';
import { ConvertCountryCodeToName } from '@utils/convertCountryCodeToName.util';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
import { handleSetCurrentPage } from '@state/medical-product/handleSetStateOrder/handleSetStateOrder';

export interface OrderType {
  id: string;
  userName: string;
  country: string;
  requestDate: string;
  result: string;
  reviewDate: string;
}

export interface MedicalOrderType {
  medicalOrders: OrderType[];
  totalPages: number;
  currentPage: number;
  totalCount: number;
}

const ListOrderPage = () => {
  const initPagination = {
    page: useSelector((state: RootState) => state.medicalOrder.currentPage),
    pageSize: 10,
  };
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState<MedicalOrderType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState(initPagination);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleGetListMedicalOrder = async () => {
    setIsLoading(true);
    const params: MedicalParams = {
      pageNumber: pagination.page,
      pageSize: pagination.pageSize,
      search: searchValue,
    };
    const response = await medicalApi.getListMedicalOrder(params);
    if (response.data.success) {
      setOrderData(response.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetListMedicalOrder();
  }, [pagination, searchValue]);
  const transferDate = (date: string) => {
    if (!date) return null;
    const transferDate = new Date(date);
    const day = String(transferDate.getDate()).padStart(2, '0');
    const month = String(transferDate.getMonth() + 1).padStart(2, '0');
    const year = transferDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const columns: ColumnsType<OrderType> = [
    {
      title: 'Order ID',
      className: 'column-first-item',
      render: (data: OrderType) => (
        <p>
          {orderData?.medicalOrders
            ? orderData?.medicalOrders.indexOf(data) + (orderData.currentPage - 1) * 10 + 1
            : 0}
        </p>
      ),
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Country',
      key: 'country',
      dataIndex: 'country',
      render: (country) => {
        return <>{ConvertCountryCodeToName(country)}</>;
      },
    },
    {
      title: 'Request date',
      dataIndex: 'requestDate',
      key: 'requestDate',
      render: (date) => {
        const dateString = transferDate(date);
        return <div>{dateString}</div>;
      },
    },
    {
      title: 'Result',
      key: 'status',
      dataIndex: 'status',
      render: (data) => {
        const color = getTagColor(data);
        return (
          <>
            {data.toLowerCase() !== 'nonprescription' && (
              <Tag
                className={`rounded-2xl w-[88px] flex justify-center h-[21px] text-[14px] font-medium `}
                style={{ color: color?.textColor }}
                color={color?.backgroundColor}
                key={data}
              >
                • {data}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Review date',
      key: 'reviewDate',
      dataIndex: 'reviewDate',
      render: (date) => {
        const dateString = transferDate(date);
        return <div>{dateString ?? ''}</div>;
      },
    },
    {
      title: 'Detail',
      key: 'action',
      dataIndex: 'id',
      className: 'column-last-item',
      render: (index) => {
        return (
          <p
            className=" text-[16px] text-[#2F6BFF] underline  decoration-solid hover:text-[#5384ff] cursor-pointer"
            onClick={() => {
              navigate(`/listOrder/${index}/order-detail`);
            }}
          >
            View
          </p>
        );
      },
    },
  ];

  const handleChangePagination = (pagination: any) => {
    handleSetCurrentPage(pagination.current || initPagination.page);
    setPagination({
      page: pagination.current || initPagination.page,
      pageSize: pagination.pageSize || initPagination.pageSize,
    });
  };

  const handleSearch = async (value: string) => {
    if (pagination.page !== 1) {
      setPagination(initPagination);
    }
    setTimeout(() => {
      setSearchValue(value);
    }, 1000);
  };
  return (
    <div className="p-[40px] h-full overflow-auto ">
      <div className="flex flex-row justify-between w-full h-fit">
        <p className="text-3xl text-primary font-bold w-[300px] mt-[10px]">Medical order</p>
        <SearchButton onClick={handleSearch} />
      </div>
      <div className="mt-[10px]">
        <TableComponent
          totalData={orderData?.totalCount ?? 0}
          isLoading={isLoading}
          columns={columns}
          dataSource={orderData?.medicalOrders}
          pagination={pagination}
          handleChange={handleChangePagination}
          currentPage={pagination.page}
        />
      </div>
    </div>
  );
};
export default ListOrderPage;
