import { PackType } from '../ListOfProduct.page';

export const ArrayOfNaturopathicListMedicalProduct = (pack: number, packList: PackType[]) => {
  const singlePack: PackType | undefined = packList.find((data) => data.packOrder === pack);

  if (singlePack) {
    return singlePack.naturopathics;
  } else {
    return [];
  }
};
