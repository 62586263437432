export const getTagColor = (tagName: string) => {
  let color = {
    textColor: '',
    backgroundColor: '',
  };

  if (tagName === 'Approved' || tagName === 'Complete' || tagName === 'Approved') {
    return (color = {
      textColor: '#00A151',
      backgroundColor: 'green',
    });
  }
  if (tagName === 'Rejected' || tagName === 'Rejected') {
    return (color = {
      textColor: '#E02D2D',
      backgroundColor: 'volcano',
    });
  }
  if (tagName === 'Pending' || tagName === 'Incomplete' || tagName === 'Waiting') {
    return (color = {
      textColor: '#FFA300',
      backgroundColor: 'yellow',
    });
  }
  return color;
};
