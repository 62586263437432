import loggedClient from '../https/loggedClient.http';
import axios from 'axios';

const baseDocument = '/documents';

const uploadApi = {
  requestUpload: (documentType: string) =>
    loggedClient.post(`${baseDocument}/requestUpload`, { documentType }),
  upload: (data: FormData) =>
    loggedClient.post(`${baseDocument}/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  uploadImage: (data: Blob, urlUpload: String, contentType: String) =>
    axios.put(`${urlUpload}`, data, {
      headers: { 'Content-Type': `${contentType}` },
    }),
};

export default uploadApi;
