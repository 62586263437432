import { NormalFont16Black, NormalFont18Black } from '@components/text-components/TextComponents';
import { OrderSummary } from './OrderDetail';

interface OrderSummaryDetailProps {
  orderSummary: OrderSummary;
}

export const OrderSummaryDetail = ({ orderSummary }: OrderSummaryDetailProps) => {
  return (
    <div className=" rounded-xl h-fit border-2 border-[#F5F5F5] border-solid mt-[16px]">
      {orderSummary.nonPrescribedPacks.length > 0 && (
        <div className="w-full h-fit bg-[#F5F5F5] rounded-xl">
          <div className="p-[16px]">
            <NormalFont18Black text="Non-prescribed packs" />
          </div>
          {orderSummary.nonPrescribedPacks.map((e) => (
            <div className="!bg-white p-[16px] rounded-b-xl">
              <NormalFont16Black text={`• ${e.medicineName}`} />
            </div>
          ))}
        </div>
      )}
      {orderSummary.prescribedPacks.length > 0 && (
        <div className="w-full h-fit bg-[#F5F5F5] ">
          <div className="p-[16px]">
            <NormalFont18Black text="Prescribed packs" />
          </div>
          {orderSummary.prescribedPacks.map((e) => (
            <div className="!bg-white p-[16px] rounded-b-xl">
              <NormalFont16Black text={`• ${e.medicineName}`} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
