import Table, { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Checkbox, Collapse, message, Skeleton, Spin } from 'antd';
import CustomExpandIcon from './CustomExpandIcon';
import DiagnosticContainer from '@pages/private-chat/privateChatDiagnostic/DiagnosticContainer';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
import UserInformationPrivateChat from '@components/UserInformationPrivateChat';
import ArrowRight from '@assets/images/icons/arrow-right.svg';
import {
  handleAnaProgramAddChatId,
  handleAnaProgramAddUserId,
  handleSetAnaProgramIsExpandFull,
  handleSetAnaProgramIsQuicklyScreen,
} from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';
import { store } from '@state/store';
import { addUserProfile, resetUserProfile } from '@state/user/userReducer';
import {
  handleSetCurrentChatItem,
  handleSetFirstLoading,
  handleSetSubscriptionExpired,
} from '@state/chat/handleSetStateChat/handleSetStateChat';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '@pages/private-chat/HandleAccessFirebase';
import { useNavigate } from 'react-router-dom';
import ViewChatTextArea from '@pages/private-chat/privateChatSubscription/ViewChatTextArea';
import ChatTextArea from '@pages/private-chat/privateChatSubscription/ChatTextArea';
import '../UserProfile.styles.scss';
import DiagnosticUserModel, {
  DiagnosesTypeDetail,
  DiagnosticModel,
  DiagnosticQuestionUserAnswersDetail,
} from '@models/Diagnostic.model';
import diagnosticApi from '@apis/diagnostic.api';
import UserDetailModel from '@models/UserDetail.model';

const { Panel } = Collapse;

const summaryDiagnosesData = [
  {
    title: 'You may have',
  },
  {
    title: "Can't rule out",
  },
  {
    title: 'Nutraceutical information',
  },
];

interface UserOrderDetailProps {
  diagnosticList: DiagnosticUserModel[];
  userDetail: UserDetailModel | undefined;
}

const UserDiagnosticDetail = ({ diagnosticList, userDetail }: UserOrderDetailProps) => {
  const [activeId, setActiveId] = useState<string>(diagnosticList[diagnosticList.length - 1].id);
  const [diagnosesActive, setDiagnosesActive] = useState<string>(summaryDiagnosesData[0].title);
  const [diagnosticDetail, setDiagnosticDetail] = useState<DiagnosticModel>();
  const [isClick, setIsClick] = useState<boolean>(
    useSelector((state: RootState) => state.anaProgram.isQuicklyScreen)
  );
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentChatItem = useSelector((state: RootState) => state.anaProgram.chatId);
  const diagnosticChatId = `session-user-${userDetail?.id}`;
  const navigate = useNavigate();
  const handleChangeList = async () => {
    handleSetAnaProgramIsExpandFull(true);
    handleSetFirstLoading(false);
    handleSetCurrentChatItem(currentChatItem);
    handleAnaProgramAddUserId(userDetail?.id || '');
    setIsNavigating(false);
    navigate('/privateChat');
  };

  const handleGetMessage = async (chatId: string) => {
    const getDetail = doc(collection(db, `ana-private-chat/private-chat/sessions`), chatId);
    const getSubscriptionDetail = await getDoc(getDetail);
    handleSetSubscriptionExpired(getSubscriptionDetail.data()?.subscriptionExpired);
  };
  const handleGetUserDetail = async (
    userId: string | undefined,
    userName: string | undefined,
    userEmail: string | undefined
  ) => {
    try {
      const payload = {
        id: userId ?? '',
        userName: userName ?? '',
        email: userEmail ?? '',
      };
      store.dispatch(addUserProfile(payload));
      setIsChatLoading(false);
    } catch (error) {
      message.error('Something error');
    }
  };

  const expandCustomComponent = (value: DiagnosesTypeDetail, index: number) => {
    return (
      <Panel
        className="mb-[24px] !border-0 bg-[#F5F5F5] !rounded-2xl !p-[20px] collapse-panel-container"
        header={value.diagnosis}
        key={index}
      />
    );
  };

  const noDataComponent = () => {
    return (
      <div className="w-full flex justify-center">
        <p>No Data Available</p>
      </div>
    );
  };

  const handleGetDiagnosticDetail = async (diagnosticId: string) => {
    try {
      setIsLoading(true);
      const response = await diagnosticApi.getDiagnosticDetail(diagnosticId);
      if (response.data.success) {
        console.log(response.data.data, 'hello');
        setDiagnosticDetail(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetDiagnosticDetail(activeId);
  }, [diagnosticList, activeId]);
  const columns: ColumnsType<DiagnosticQuestionUserAnswersDetail> = [
    {
      title: 'No.',
      className: 'column-first-item ',
      width: '4%',
      key: 'no',
      render: (data: DiagnosticQuestionUserAnswersDetail) => (
        <p className="m-0 p-0">
          {diagnosticDetail?.diagnosticQuestionUserAnswers
            ? diagnosticDetail?.diagnosticQuestionUserAnswers?.indexOf(data) + 1
            : 0}
        </p>
      ),
    },

    {
      title: 'Question',
      dataIndex: 'question',
      className: '',
      key: 'question',
      width: '46%',
      render: (question) => {
        return <p className=" p-0 m-0 w-full">{question}</p>;
      },
    },
    {
      title: 'Answer',
      key: 'answers',
      className: 'column-last-item ',
      dataIndex: 'answers',
      width: '46%',
      render: (answers: string[]) => {
        return (
          <div className="m-0 p-0 flex flex-col">
            {answers.map((answer, index) => (
              <p key={index} className="m-0 p-0">
                {answer}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-[8px]">
      <div className="flex flex-wrap h-fit w-full max-w-full">
        {diagnosticList.map((data, index) => (
          <div
            className={` h-fit w-fit p-[10px] flex justify-center text-[16px] font-bold ${
              data.id === activeId
                ? 'text-primary bg-[#F2F3F5] rounded-2xl'
                : 'text-[#919191] cursor-pointer'
            } mt-[10px]`}
            onClick={() => {
              setActiveId(data.id || '');
            }}
          >
            {`Diagnostic #${index + 1}`}
          </div>
        ))}
      </div>
      {isLoading ? (
        <Skeleton className="mt-[24px]" />
      ) : (
        <div>
          <div className="mt-[20px] gap-4 h-fit w-full">
            <DiagnosticContainer
              title="General statement"
              detail={diagnosticDetail?.diagnosticSummary.generalStatements?.join(' ') || ''}
            />
          </div>
          <div>
            <div className="flex flex-row justify-between mt-[40px] mb-[8px]">
              <p className="text-[24px] font-bold text-[#2F2F2F] ">
                Answer to all questions in the diagnostics
              </p>
              <p
                className="text-primary hover:underline font-medium text-[16px] mt-[12px] cursor-pointer"
                onClick={() => {
                  setIsChatLoading(true);
                  handleGetMessage(diagnosticChatId);
                  setIsActive(true);
                  setIsClick(true);
                  handleSetAnaProgramIsQuicklyScreen(true);
                  handleSetCurrentChatItem(diagnosticChatId);
                  handleAnaProgramAddChatId(diagnosticChatId);
                  handleGetUserDetail(userDetail?.id, userDetail?.name, userDetail?.email);
                }}
              >
                View user chat
              </p>
            </div>
            <div className="">
              <Table
                className="table-user-profile "
                columns={columns}
                dataSource={diagnosticDetail?.diagnosticQuestionUserAnswers}
                pagination={false}
                scroll={{
                  y: 444,
                }}
              />
            </div>
            <div className="flex flex-row mt-[20px] space-x-5 w-full diagnostic-detail-container cursor-pointer">
              {summaryDiagnosesData.map((data) => (
                <div
                  className={`w-fit h-fit p-[10px] flex justify-center text-[20px]   font-bold  ${
                    data.title === diagnosesActive ? 'text-primary  ' : 'text-[#919191]'
                  } mt-[10px]`}
                  onClick={() => {
                    setDiagnosesActive(data.title);
                  }}
                >
                  {data.title}
                </div>
              ))}
            </div>

            <div className="mt-[20px]">
              {diagnosesActive === summaryDiagnosesData[2].title ? (
                <Collapse
                  className="mb-[24px] !border-0 !bg-white"
                  defaultActiveKey={['0']}
                  expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive ?? true} />}
                >
                  {diagnosticDetail?.diagnosticSummary.diagnosticMultiNaturopathicPacks?.length ===
                  0 ? (
                    noDataComponent()
                  ) : (
                    <>
                      {diagnosticDetail?.diagnosticSummary.diagnosticMultiNaturopathicPacks?.map(
                        (value, index) => (
                          <Panel
                            className="mb-[24px] !border-0 bg-[#F5F5F5] !rounded-2xl !p-[12px] collapse-panel-container"
                            header={value.pack}
                            key={index}
                          >
                            {value.products?.map((data) => (
                              <div className="flex flex-row gap-3 item-center">
                                <Checkbox
                                  checked={diagnosticDetail?.diagnosticSummary.boughtProducts?.some(
                                    (product) =>
                                      product.productId === data.productId &&
                                      product.packOrder === value.packOrder
                                  )}
                                  className=" checkbox-diagnostic"
                                  type="checkbox"
                                  disabled
                                />
                                <p className="text-[14px] text-[400] text-[#606060] pt-1">
                                  {data.name} - {data.direction}
                                </p>
                              </div>
                            ))}
                          </Panel>
                        )
                      )}
                    </>
                  )}
                </Collapse>
              ) : (
                <>
                  {diagnosesActive === summaryDiagnosesData[0].title ? (
                    <>
                      {diagnosticDetail?.diagnosticSummary.diagnoses.youMayHaves?.length === 0
                        ? noDataComponent()
                        : diagnosticDetail?.diagnosticSummary.diagnoses.youMayHaves?.map(
                            (value, index) => expandCustomComponent(value, index)
                          )}
                    </>
                  ) : (
                    <>
                      {diagnosticDetail?.diagnosticSummary.diagnoses.dontRuleOuts?.length === 0
                        ? noDataComponent()
                        : diagnosticDetail?.diagnosticSummary.diagnoses.dontRuleOuts?.map(
                            (value, index) => expandCustomComponent(value, index)
                          )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {isClick && (
        <div
          className={`absolute top-0 right-0 z-40 bg-white w-[600px] h-full shadow-lg slide-left ${
            isActive ? 'slide-left-detail' : 'slide-right-detail'
          }`}
        >
          <div className={`w-full h-full  flex flex-col`}>
            <div className="view-detail-chat-header h-[146px] w-full flex flex-row justify-between">
              <div className=" w-[95%]">
                <UserInformationPrivateChat
                  userName={userDetail?.name}
                  body={userDetail?.email || ''}
                  isClick={false}
                ></UserInformationPrivateChat>
              </div>
              <div className={` pt-[40px] pr-[12px]  cursor-pointer`}>
                <img
                  alt=""
                  src={ArrowRight}
                  onClick={() => {
                    handleSetAnaProgramIsQuicklyScreen(false);
                    setIsActive(false);
                    setTimeout(() => {
                      setIsClick(false);
                      store.dispatch(resetUserProfile());
                      handleSetFirstLoading(true);
                    }, 998);
                  }}
                />
              </div>
            </div>
            <div className="flex justify-center bg-[#E2EBFF] ">
              <p
                className="p-[4px] m-0 text-[16px] cursor-pointer text-[#2F6BFF] underline"
                onClick={() => {
                  handleChangeList();
                }}
              >
                Direct to full chat screen{' '}
                {isNavigating && <Spin className="ml-[10px]" size="small" />}
              </p>
            </div>

            <ViewChatTextArea
              loadingDetailChat={isChatLoading}
              finishLoad={() => {}}
              currentChatItem={currentChatItem}
            ></ViewChatTextArea>
            <ChatTextArea
              chatId={currentChatItem ? currentChatItem : ''}
              currentUserId={userDetail?.id ?? ''}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default UserDiagnosticDetail;
